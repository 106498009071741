import ApiService from "./api.service";

export function planScanpay(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/plan/scanpay", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}

export function ticketScanpay(data) {
  return new Promise((resolve, reject) => {
    ApiService.post("/portal/ticket/scanpay", data)
      .then(({ data }) => {
        resolve(data);
      })
      .catch(({ response }) => {
        reject(response);
      });
  });
}
